header {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 70px 10% 0;
}

.home-content {
  max-width: 600px;
  position: relative;
  margin-top: -5%;
}

.home-content h3 {
  font-size: 32px;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 0.7s;
}
.home-content h1 {
  font-size: 56px;
  font-weight: 700;
  margin: -3px 0;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1.5s;
}

.home-content h3:nth-of-type(2) {
  margin-bottom: 30px;
  animation: slideTop 1s ease forwards;
  animation-delay: 0.7s;
}

.home-content p {
  font-size: 16px;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 0.7s;
}

.home-content h3 span {
  color: #0ef;
}

.home-img img {
  background: linear-gradient(var(--color-primary), transparent);
  margin-top: -6rem;
  border-radius: 51% 49% 53% 47% / 40% 30% 70% 60%;
  max-width: 450px;
  padding: 80px 0 0 0;
  margin-top: -25%;
  margin-right: -20px;
  margin-left: 10%;
  opacity: 0;
  animation: zoomIn 1s ease forwards, floatImage 4s ease-in-out infinite;
  animation-delay: 2s, 3s;
  overflow: hidden;
}

.social-media a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: transparent;
  border: 2px solid #0ef;
  border-radius: 50%;
  font-size: 20px;
  color: var(--color-primary);
  text-decoration: none;
  margin: 30px 15px 30px 0;
  transition: 0.5s ease;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: calc(0.2s * var(--i));
}

.social-media a:hover {
  background: var(--color-primary);
  color: #1f242d;
  box-shadow: 0 0 20px #0ef;
}

.download-btn {
  display: inline-block;
  padding: 12px 28px;
  background: var(--color-primary);
  text-decoration: none;
  border-radius: 40px;
  box-shadow: 0 0 10px #0ef;
  font-size: 16px;
  color: #1f242d;
  letter-spacing: 1px;
  font-weight: 600;
  opacity: 0;
  animation: slideTop 1s ease forwards;
  animation-delay: 2s;
}

.scroll__down {
  position: absolute;
  bottom: -10rem;
  left: -2rem;
  text-decoration: none;
  color: red;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* opacity: 0;
  animation: zoomIn 1s ease forwards, floatImage 4s ease-in-out infinite;
  animation-delay: 3s, 5s; */
}

.scroll__down a {
  text-decoration: none;
}

.down-arrow {
  font-size: 3rem;
}

/*KEY FRAMES*/

@keyframes slideBottom {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideTop {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideRight {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideLeft {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes floatImage {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-24px);
  }
  100% {
    transform: translateY(0);
  }
}
